import { FileTypes } from '../../types/global.type';

const fileTypes: FileTypes = {
  png: {
    description: 'PNG Image',
    accept: { 'image/png': ['.png'] },
  },
  pdf: {
    description: 'PDF Document',
    accept: { 'application/pdf': ['.pdf'] },
  },
  zip: {
    description: 'ZIP Archive',
    accept: { 'application/zip': ['.zip'] },
  },
  json: {
    description: 'JSON File',
    accept: { 'application/json': ['.json'] },
  },
};

const saveFile = async (url: string) => {
  if (typeof url !== 'string') return;

  // Check if the URL points to a PDF or image (these should open in a new tab)
  // I have moved this check up to avoid any interference with the showSaveFilePicker API
  const fileExtension = url.split('.').pop()?.toLowerCase();
  const isPdfOrImage = ['pdf', 'jpg', 'jpeg', 'png'].includes(
    fileExtension || '',
  );

  if (isPdfOrImage) {
    // Always open PDFs and images in a new tab
    window.open(url, '_blank');
    return;
  }

  if (!('showSaveFilePicker' in window)) return download(url);

  let fetchError = false;
  let res = await window.fetch(url);
  let blob: Blob | void;

  if (res) blob = await res.blob();
  else fetchError = true;

  if (!blob || fetchError) return download(url);

  let fileNameEndIndex = url.indexOf('?');

  const suggestedFilename = url.slice(
    url.lastIndexOf('/') + 1,
    fileNameEndIndex !== -1 ? fileNameEndIndex : undefined,
  );
  const extension = suggestedFilename.slice(
    suggestedFilename.lastIndexOf('.') + 1,
  );
  const options = {
    suggestedName: suggestedFilename,
    types: [fileTypes[extension]],
  };
  const fileHandle = await (window as any).showSaveFilePicker(options);

  if (!fileHandle) return download(url);

  const writer = await fileHandle.createWritable();
  await writer.write(blob);
  await writer.close();
};

export default saveFile;

const download = (url: string) => {
  const a: HTMLAnchorElement = document.createElement('a');
  a.style.display = 'none';
  document.body.appendChild(a);

  // Open in new window if file type is an image or PDF
  const fileExtension = url.split('.').pop()?.toLowerCase();
  const isImageOrPdf =
    fileExtension && ['jpg', 'jpeg', 'png', 'pdf'].includes(fileExtension);

  if (isImageOrPdf) {
    a.target = '_blank'; // Open in new tab
  }

  a.href = url;
  a.click();

  window.URL.revokeObjectURL(url);
  a.parentElement?.removeChild(a);
};
