import { Formik, FormikHelpers } from 'formik';
import StepForm from '../StepForm';
import { FormOnlyStepProps } from '../../../types/stepFlow.type';
import styles from './formOnlyStep.module.scss';
import { useState } from 'react';
import Button from '../../global/Button';
import { objectToFormData } from '../../../utils/helpers/analyzeTrial';

const FormOnlyStepContainer = ({
  formElements,
  children,
  uniqueKey,
  rightHalfElement,
  submitButtonText,
  onSubmitMutationRef,
  successfulSubmitCallback,
  valuesCallback,
  ...rest
}: FormOnlyStepProps) => {
  const [submissionError, setSubmissionError] = useState<string | null>(null);

  let submitMutation: null | ((values: any) => Promise<void>) = null;
  let mutationData: {
    isLoading?: boolean;
  } = {};

  if (typeof onSubmitMutationRef === 'function') {
    [submitMutation, mutationData] = onSubmitMutationRef();
  }

  const { isLoading } = mutationData;

  const handleSubmit = submitMutation
    ? async (values: any, { setSubmitting }: FormikHelpers<any>) => {
        setSubmissionError(null);
        try {
          if (submitMutation) {
            await submitMutation(objectToFormData(values)).then(
              (resultData: any) => {
                if (resultData.error) {
                  if (resultData?.error?.data?.error) {
                    throw new Error(resultData.error.data.error);
                  } else if (resultData?.error?.error) {
                    throw new Error(resultData.error.error);
                  } else {
                    throw new Error(resultData.error);
                  }
                }
                if (successfulSubmitCallback) {
                  successfulSubmitCallback(resultData);
                }
              },
            );
          }
        } catch (error: any) {
          setSubmissionError(`Something went wrong. ${error}`);
        } finally {
          setSubmitting(false);
        }
      }
    : () => {};

  const submitButton = submitButtonText && (
    <Button
      type="submit"
      variant="brand"
      disabled={isLoading}
      action={'button'}
    >
      {isLoading ? submitButtonText.submitting : submitButtonText.default}
    </Button>
  );

  return (
    // uniqueKey is required here because otherwise, if we attempt to use two
    // FormOnlyStepContainers sequentially in the same step flow, the Formik
    // components are partially treated by React as the same instance, and will
    // have the same FormikContext, which means different steps would share the
    // same form state, values would be unpredictably passed across forms in
    // random fields, etc.
    //
    // If we ever need to use another type of step that does not
    // wrap FormOnlyStepContainer more than once in a step flow, it will also
    // need to have the same keying applied.

    <Formik
      key={uniqueKey}
      onSubmit={handleSubmit}
      validateOnMount
      validateOnChange={uniqueKey !== 'treatments'}
      enableReinitialize
      {...rest}
    >
      <>
        <StepForm
          key={`${uniqueKey}-form`}
          formElements={formElements}
          rightHalfElement={rightHalfElement}
          valuesCallback={valuesCallback}
        >
          {children}
          {onSubmitMutationRef && (
            <div className={styles['form-only-step-submit-button-container']}>
              {submitButton}
            </div>
          )}
          {submissionError && (
            <div className={styles['form-only-step-submit-error']}>
              {submissionError}
            </div>
          )}
        </StepForm>
      </>
    </Formik>
  );
};

export default FormOnlyStepContainer;
