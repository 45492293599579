import FormOnlyStepContainer from '../../step-flow/FormOnlyStep';
import {
  FormElementPropsType,
  FormOnlyStepProps,
  HorizontalInputPairProps,
} from '../../../types/stepFlow.type';

interface TreatmentReviewStepProps extends FormOnlyStepProps {
  productName: string;
  rateUnits: string;
  treatmentRates: string;
  treatmentLabels: string;
  nominalRate: number;
  nominalLabel: string;
  analysisTreatmentOverride: Record<string, any>;
}

const TreatmentReviewStepContainer = ({
  productName,
  rateUnits,
  treatmentRates,
  treatmentLabels,
  nominalRate,
  nominalLabel,
  analysisTreatmentOverride,
  ...rest
}: TreatmentReviewStepProps) => {
  let splitTreatmentRates = treatmentRates.split(',').map(Number);
  let splitTreatmentLabels: string[] = [];
  if (treatmentLabels && treatmentLabels !== 'Use rates') {
    splitTreatmentLabels = treatmentLabels.split(',');
  }

  if (analysisTreatmentOverride) {
    // if we already have this data saved in the analysis, use it instead of the trial values
    splitTreatmentRates =
      analysisTreatmentOverride.trial_design_field_value?.treatment_rates ||
      splitTreatmentRates;
    splitTreatmentLabels =
      analysisTreatmentOverride.trial_design_field_value?.treatment_labels ||
      splitTreatmentLabels;
    nominalRate =
      analysisTreatmentOverride.trial_design_field_value?.nominal_rate ||
      nominalRate;
    nominalLabel =
      analysisTreatmentOverride.trial_design_field_value?.nominal_label ||
      nominalLabel;
  }

  // assemble treatment rate elements assuming no labels
  let treatmentRateElements: FormElementPropsType[] = splitTreatmentRates.map(
    (rate, index) => {
      return {
        name: `treatment_rate_${index}`,
        label: `${index + 1}`,
        value: rate,
        rightElement: rateUnits,
      };
    },
  );

  let treatmentRateHeading: FormElementPropsType = {
    element: 'sectionHeading',
    label: 'Rates',
    align: 'singleInput',
  } as FormElementPropsType;

  // replace with horizontalinputpairs if labels are present
  if (splitTreatmentLabels.length > 0) {
    treatmentRateElements = splitTreatmentLabels.map((label, index) => {
      return {
        element: 'horizontalInputPair',
        elements: [
          {
            name: `treatment_rate_${index}`,
            label: `${index + 1}`,
            value: splitTreatmentRates[index],
            rightElement: rateUnits,
          },
          {
            name: `treatment_label_${index}`,
            label: `${index + 1}`,
            value: label,
          },
        ],
      } as HorizontalInputPairProps;
    });
    treatmentRateHeading = {
      element: 'horizontalInputPair',
      elements: [
        { element: 'sectionHeading', label: 'Rate' },
        { element: 'sectionHeading', label: 'Label' },
      ],
    };
  }

  // same process for nominal
  let nominalRateElement: FormElementPropsType = {
    name: `nominal_rate`,
    label: `${splitTreatmentRates.indexOf(nominalRate) + 1}`,
    value: nominalRate,
    rightElement: rateUnits,
  };
  let nominalHeading: FormElementPropsType = {
    element: 'sectionHeading',
    label: 'Rate',
    align: 'singleInput',
  } as FormElementPropsType;

  if (nominalLabel) {
    nominalRateElement = {
      element: 'horizontalInputPair',
      elements: [
        {
          name: 'nominal_rate',
          label: `${splitTreatmentRates.indexOf(nominalRate) + 1}`,
          value: nominalRate,
          rightElement: rateUnits,
        },
        {
          name: 'nominal_label',
          label: `${splitTreatmentRates.indexOf(nominalRate) + 1}`,
          value: nominalLabel,
        },
      ],
    };
    nominalHeading = {
      element: 'horizontalInputPair',
      elements: [
        { element: 'sectionHeading', label: 'Rate' },
        { element: 'sectionHeading', label: 'Label' },
      ],
    };
  }

  // assemble all our FormElements
  const formElements: FormElementPropsType[] = [
    {
      element: 'sectionHeading',
      label: `${productName} - Test Treatments`,
      align: 'singleInput',
    },
    treatmentRateHeading,
    ...treatmentRateElements,
    {
      element: 'sectionHeading',
      label: 'Nominal Treatment',
      align: 'singleInput',
    },
    nominalHeading,
    nominalRateElement,
  ];

  const initialValues = formElements.reduce((values, element) => {
    if ('elements' in element && element.element === 'horizontalInputPair') {
      element.elements.forEach((subElement) => {
        if ('name' in subElement && 'value' in subElement) {
          values[subElement.name] = subElement.value;
        }
      });
    } else {
      if ('name' in element) {
        values[element.name] = element.value;
      }
    }

    return values;
  }, {} as Record<string, any>);

  return (
    <div>
      <FormOnlyStepContainer
        {...rest}
        formElements={formElements}
        initialValues={initialValues}
      >
        {rest.children}
      </FormOnlyStepContainer>
    </div>
  );
};

export default TreatmentReviewStepContainer;
